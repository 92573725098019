exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-board-blackbox-landscape-lcd-tsx": () => import("./../../../src/pages/board/blackbox-landscape-lcd.tsx" /* webpackChunkName: "component---src-pages-board-blackbox-landscape-lcd-tsx" */),
  "component---src-pages-board-class-700-tsx": () => import("./../../../src/pages/board/class-700.tsx" /* webpackChunkName: "component---src-pages-board-class-700-tsx" */),
  "component---src-pages-board-daktronics-data-display-dmi-tsx": () => import("./../../../src/pages/board/daktronics-data-display-dmi.tsx" /* webpackChunkName: "component---src-pages-board-daktronics-data-display-dmi-tsx" */),
  "component---src-pages-board-infotec-landscape-dmi-tsx": () => import("./../../../src/pages/board/infotec-landscape-dmi.tsx" /* webpackChunkName: "component---src-pages-board-infotec-landscape-dmi-tsx" */),
  "component---src-pages-board-tsx": () => import("./../../../src/pages/board.tsx" /* webpackChunkName: "component---src-pages-board-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

